import { useLocation } from 'react-router';
import { useSlots } from '@gonfalon/react';
import { Tab, TabList, TabPanel, Tabs } from '@launchpad-ui/components';

import { EnvironmentController } from '../internal/EnvironmentController';
import { useEnvironmentMode } from '../internal/useEnvironmentMode';

import styles from './ProjectEntityTabs.module.css';

export function ProjectEntityTab({ children, ...props }: React.ComponentProps<typeof Tab>) {
  return (
    <Tab className={styles.tab} id={`${props.href}`} key={`${props.href}`} href={props.href} {...props}>
      {children}
    </Tab>
  );
}

export function ProjectEntityTabPanel({ children }: { children: React.ReactNode }) {
  const hasEnvironmentSelector = useEnvironmentMode() !== 'all';
  const { pathname } = useLocation();

  return (
    <TabPanel id={pathname} className={styles.tabPanel}>
      {hasEnvironmentSelector ? (
        <div className={styles.context}>
          <EnvironmentController />
        </div>
      ) : null}
      {children}
    </TabPanel>
  );
}

export function ProjectEntityTabs({
  children,
  ...props
}: React.ComponentProps<typeof Tabs> & { children: React.ReactNode }) {
  const [slots, restChildren] = useSlots(children, {
    tabPanel: ProjectEntityTabPanel,
  });
  const { pathname } = useLocation();

  return (
    <Tabs key={pathname} selectedKey={pathname} className={styles.tabs} {...props}>
      <TabList className={styles.tabList}>{restChildren}</TabList>
      {slots.tabPanel}
    </Tabs>
  );
}
