import { type JSX, ReactNode, useRef } from 'react';
import { mediaQueries, useMediaQuery } from '@gonfalon/dom';
import { TextTruncator } from '@gonfalon/launchpad-experimental';
import { useSlots } from '@gonfalon/react';
import { useInteractOutside } from '@react-aria/interactions';
import clsx from 'clsx';
import invariant from 'tiny-invariant';

import { EnvironmentController } from '../internal/EnvironmentController';
import { KeyboardShortcut } from '../internal/keyboardShortcuts';
import { LayoutHeader } from '../internal/LayoutHeader';
import { useEnvironmentMode } from '../internal/useEnvironmentMode';
import { useSidebarState } from '../internal/useSidebarState';

import { ProjectEntityDescription } from './ProjectEntityDescription';
import { ProjectEntitySidebarContext } from './ProjectEntitySidebarContext';
import { ProjectEntityTabs } from './ProjectEntityTabs';
import { ResizableSidebarLayout, ResizeHandle, useResizableSidebarLayoutState } from './ResizableSidebarLayout';
import { useProjectEntitySidebarContext } from './useProjectEntitySidebarContext';

import styles from './ProjectEntityLayout.module.css';

type LayoutMode = 'content' | 'tabbed' | 'configuration';

export function ProjectEntityLayout({ children }: { children?: ReactNode }) {
  const [{ banner, content, sidebar, tabs }] = useSlots(children, {
    sidebar: ProjectEntitySidebar,
    content: ProjectEntityContent,
    banner: ProjectEntityBanner,
    tabs: ProjectEntityTabs,
  });

  const sidebarState = useSidebarState({
    breakpoint: '46.1875rem',
    toggleShortcut: KeyboardShortcut.TOGGLE_PROJECT_ENTITY_SIDEBAR,
  });

  const layoutState = useResizableSidebarLayoutState({
    initialSidebarWidth: 300,
    minimumSidebarWidth: 300,
    maximumSidebarWidth: 480,
  });

  invariant(sidebar, 'ProjectEntityLayout must include a ProjectEntitySidebar');

  const hasEnvironmentSelector = useEnvironmentMode() !== 'all';

  let layoutMode: LayoutMode = 'content';
  if (tabs) {
    layoutMode = 'tabbed';
  } else {
    invariant(content, 'ProjectEntityLayout in content mode must include a ProjectEntityContent');
  }

  return (
    <ResizableSidebarLayout state={layoutState}>
      <ProjectEntitySidebarContext.Provider value={sidebarState}>
        <div
          className={styles.entity}
          data-has-sidebar={sidebarState.isVisible ? 'true' : undefined}
          data-dragging={layoutState.isDragging ? 'true' : undefined}
        >
          <div className={styles.workspace}>
            <LayoutHeader />
            {banner}
            {layoutMode === 'content' && (
              <>
                {hasEnvironmentSelector ? (
                  <div className={styles.context}>
                    <EnvironmentController />
                  </div>
                ) : null}
                <div className={styles.main}>{content}</div>
              </>
            )}
            {layoutMode === 'tabbed' && <div className={styles.main}>{tabs}</div>}
          </div>
          <div className={styles.aside} aria-hidden={sidebarState.isVisible ? undefined : 'true'}>
            <ResizeHandle />
            {sidebar}
          </div>
        </div>
      </ProjectEntitySidebarContext.Provider>
    </ResizableSidebarLayout>
  );
}

export function ProjectEntityIntro({ children }: { children: ReactNode }) {
  const [{ title, description, code }] = useSlots(children, {
    title: ProjectEntityTitle,
    description: ProjectEntityDescription,
    code: ProjectEntityCode,
  });

  invariant(title, 'ProjectEntityIntro must include a ProjectEntityTitle');

  return (
    <div className={styles.intro}>
      {title}
      {description}
      {code}
    </div>
  );
}

export function ProjectEntityTitle({ children }: { children: ReactNode }) {
  return <h1 className={styles.title}>{children}</h1>;
}

export function ProjectEntityCode({ children }: { children: ReactNode }) {
  return <div className={styles.code}>{children}</div>;
}

export function ProjectEntitySidebar({ children }: { children: ReactNode }) {
  const sidebar = useProjectEntitySidebarContext();
  const [{ intro, actions }, rest] = useSlots(children, {
    intro: ProjectEntityIntro,
    actions: ProjectEntityActions,
  });

  const isSmall = useMediaQuery(mediaQueries.MOBILE);
  const sidebarRef = useRef<HTMLDivElement>(null);
  useInteractOutside({
    ref: sidebarRef,
    isDisabled: !sidebar.isVisible || !isSmall,
    onInteractOutside() {
      if (sidebar.isVisible) {
        sidebar.toggle();
      }
    },
  });

  return (
    <div className={clsx(styles.sidebar, { [styles.isVisible]: sidebar.isVisible })} ref={sidebarRef}>
      {actions}
      {intro}
      <div className={styles.properties}>{rest}</div>
    </div>
  );
}

export function ProjectEntityActions({ children }: { children: ReactNode }) {
  return <div className={styles.actions}>{children}</div>;
}

export function ProjectEntityContent({ children }: { children: ReactNode }) {
  return <div className={styles.content}>{children}</div>;
}

export function ProjectEntityBanner({ children }: { children: ReactNode }) {
  return <div className={styles.banner}>{children}</div>;
}

export function EntityProperty({ children }: { children?: ReactNode }) {
  const [slots] = useSlots(children, {
    title: EntityPropertyTitle,
    description: EntityPropertyDescription,
    action: EntityPropertyAction,
  });

  return (
    <div className={styles.property}>
      {slots.title}
      {slots.description}
      {slots.action}
    </div>
  );
}

export function EntityPropertyTitle({ children }: { children?: ReactNode }) {
  return <div className={styles.propertyTitle}>{children}</div>;
}

export function EntityPropertyDescription({ children }: { children?: ReactNode }) {
  return <div className={styles.propertyDescription}>{children}</div>;
}

export function EntityPropertyAction({ children }: { children?: ReactNode }) {
  return <div className={styles.propertyAction}>{children}</div>;
}

type EntityPropertyEmptyStateProps =
  | {
      text: string;
      actionTrigger: JSX.Element;
    }
  | {
      text: string;
      actionTrigger?: never;
    }
  | {
      text?: never;
      actionTrigger: JSX.Element;
    };

export function EntityPropertyEmptyState({ text, actionTrigger }: EntityPropertyEmptyStateProps) {
  return (
    <div className={styles.propertyEmptyState}>
      {text && <TextTruncator>{text}</TextTruncator>}
      {text && actionTrigger && <span className={styles.symbol}>•</span>}
      {actionTrigger}
    </div>
  );
}
