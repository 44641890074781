/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
/* eslint jsx-a11y/no-noninteractive-tabindex: 0 */

import { type ReactNode, createContext, useContext, useMemo, useRef, useState } from 'react';
import { IconButton, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

import { getKeyboardShortcutLabel, KeyboardShortcut } from '../internal/keyboardShortcuts';

import { useProjectEntitySidebarContext } from './useProjectEntitySidebarContext';

import styles from './ResizableSidebarLayout.module.css';

function clamp(x: number, min: number, max: number) {
  return Math.max(min, Math.min(max, x));
}

type SidebarConfiguration = {
  initial: number;
  min: number;
  max: number;
};

type ResizableSidebarLayoutState = {
  sidebarConfig: SidebarConfiguration;
  width: number;
  isDragging: boolean;
  setWidth: (width: number) => void;
  setDragging: (isDragging: boolean) => void;
};

const ResizableSidebarLayoutContext = createContext<ResizableSidebarLayoutState | undefined>(undefined);

export function useResizableSidebarLayoutState({
  initialSidebarWidth,
  minimumSidebarWidth,
  maximumSidebarWidth,
}: {
  minimumSidebarWidth: number;
  maximumSidebarWidth: number;
  initialSidebarWidth: number;
}) {
  const [width, setWidth] = useState(initialSidebarWidth);
  const [isDragging, setDragging] = useState(false);

  return useMemo(
    () => ({
      width,
      isDragging,
      setWidth,
      setDragging,
      sidebarConfig: { initial: initialSidebarWidth, min: minimumSidebarWidth, max: maximumSidebarWidth },
    }),
    [width, initialSidebarWidth, isDragging, setDragging, minimumSidebarWidth, maximumSidebarWidth],
  );
}

/**
 * A layout that supports a resizable sidebar.
 *
 * NOTE: at the moment this only works for users with pointer devices (e.g. mouse, finger, etc).
 */
export function ResizableSidebarLayout({
  state,
  children,
}: {
  state: ResizableSidebarLayoutState;
  children: ReactNode;
}) {
  return (
    <ResizableSidebarLayoutContext.Provider value={state}>
      <div
        className={styles.layout}
        style={{
          // @ts-expect-error --resizable-sidebar-size is not a known css property
          '--resizable-sidebar-size': `${String(state.width)}px`,
        }}
      >
        {children}
      </div>
    </ResizableSidebarLayoutContext.Provider>
  );
}

export function ResizeHandle() {
  const layout = useResizableSidebarLayoutContext();
  const sidebar = useProjectEntitySidebarContext();

  const originalWidth = useRef(layout.width);
  const originalClientX = useRef(layout.width);

  return (
    <div
      role="separator"
      tabIndex={0}
      className={styles.resizeHandle}
      data-dragging={layout.isDragging ? 'true' : undefined}
      data-collapsed={!sidebar.isVisible ? 'true' : undefined}
      onDragStart={() => false}
      onTouchStart={(event) => event.preventDefault()}
      onTouchMove={(event) => event.preventDefault()}
      onTouchCancel={(event) => event.preventDefault()}
      onTouchEnd={(event) => event.preventDefault()}
      onPointerDown={(event) => {
        event.preventDefault();
        event.currentTarget.setPointerCapture(event.pointerId);
        document.body.style.userSelect = 'none';
        document.body.style.touchAction = 'none';
        originalWidth.current = layout.width;
        originalClientX.current = event.clientX;
        layout.setDragging(true);
      }}
      onPointerMove={(event) => {
        event.preventDefault();

        if (!layout.isDragging) {
          return;
        }

        const distanceToViewportEdge = document.documentElement.clientWidth - event.clientX;
        if (sidebar.isVisible && distanceToViewportEdge < 50) {
          sidebar.toggle();
        }

        const delta = event.clientX - originalClientX.current;

        // We subtract the delta because the sidebar is on the right side of the screen
        const nextWidth = Math.floor(
          clamp(originalWidth.current - delta, layout.sidebarConfig.min, layout.sidebarConfig.max),
        );

        layout.setWidth(nextWidth);
      }}
      onPointerUp={() => {
        document.body.style.userSelect = '';
        document.body.style.touchAction = '';
        layout.setDragging(false);
      }}
      onPointerCancel={() => {
        document.body.style.userSelect = '';
        document.body.style.touchAction = '';
        layout.setDragging(false);
      }}
    >
      <TooltipTrigger>
        <IconButton
          className={styles.toggle}
          variant="minimal"
          size="small"
          aria-label={sidebar.isVisible ? 'Hide sidebar' : 'Show sidebar'}
          icon={sidebar.isVisible ? 'sidebar-left-collapse' : 'sidebar-left-expand'}
          onPress={sidebar.toggle}
        />
        <Tooltip placement="bottom">
          {sidebar.isVisible ? (
            <>
              Hide sidebar<kbd>{getKeyboardShortcutLabel(KeyboardShortcut.TOGGLE_PROJECT_ENTITY_SIDEBAR)}</kbd>
            </>
          ) : (
            <>
              Show sidebar<kbd>{getKeyboardShortcutLabel(KeyboardShortcut.TOGGLE_PROJECT_ENTITY_SIDEBAR)}</kbd>
            </>
          )}
        </Tooltip>
      </TooltipTrigger>
    </div>
  );
}

export function useResizableSidebarLayoutContext() {
  const context = useContext(ResizableSidebarLayoutContext);
  if (!context) {
    throw new Error('useResizableSidebarLayoutContext must be used within a ResizableSidebarLayoutProvider');
  }
  return context;
}
