import { useState } from 'react';
import { useLineClamp } from '@gonfalon/dom';
import { TextButton } from '@gonfalon/launchpad-experimental';
import { SmartMarkdown } from '@gonfalon/smart-links';
import clsx from 'clsx';

import styles from './ProjectEntityLayout.module.css';

const allowedTags = ['a', '#text', 'p', 'li', 'ol', 'ul', 'b', 'strong', 'i', 'em', 'br', 'code'];

export function ProjectEntityDescription({ text }: { text: string }) {
  const [expanded, setExpand] = useState(false);
  const { ref, hasOverflow, props: lineClampProps } = useLineClamp<HTMLDivElement>({ lines: 2, override: expanded });

  return (
    <div className={styles.description}>
      <div className={clsx(styles.text, lineClampProps.className)} ref={ref} style={lineClampProps.style}>
        <SmartMarkdown source={text} allowedTags={allowedTags} />
      </div>
      <div className={styles.override}>
        {(hasOverflow || expanded) && (
          <TextButton onPress={() => setExpand(!expanded)}>Read {expanded ? 'less' : 'more'}</TextButton>
        )}
      </div>
    </div>
  );
}
