import { useContext } from 'react';
import invariant from 'tiny-invariant';

import { ProjectEntitySidebarContext } from './ProjectEntitySidebarContext';

export function useProjectEntitySidebarContext() {
  const state = useContext(ProjectEntitySidebarContext);
  invariant(state, 'useProjectEntitySidebarContext must be used inside of a ProjectEntitySidebarContext provider');
  return state;
}
